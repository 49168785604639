import React from 'react'
import PropTypes from 'prop-types'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import pic01 from '../images/fotogemma.png'
import pic02 from '../images/dibuixllibre-capcalera.png'
import pic03 from '../images/capcalera-llibre-es.jpg'

class Main extends React.Component {
  render() {

    let close = <div className="close" onClick={() => {this.props.onCloseArticle()}}></div>

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>

        <article id="intro" className={`${this.props.article === 'intro' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Quién soy</h2>
          <span className="image2 main"><img src={pic01} alt="" /></span>
          <p>Mi nombre es Gemma Farràs Prat. Nací en L’Estany un 30 de marzo de 1975. Me licencié en Economía y trabajé varios años en la administración local. Sentía la necesidad de descubrir mundo, mi propio mundo, y decidí decir adiós a mi profesión y emprender un viaje fascinante. He hecho de la navegación a Ítaca mi objetivo de vida, descubriendo la hoja de ruta, aprendiendo a llevar el timón y disfrutando de la fuerza de mis velas. 
            Es un viaje con sentido, que va tomando forma a medida que avanzo, con todos los cambios de rumbo que considero necesarios. <i>Vida sentida</i> es mi cuaderno de bitácora, el faro que me guiará cuando la oscuridad me invada.</p>
          {close}
        </article>

        <article id="work" className={`${this.props.article === 'work' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Vida Sentida</h2>
          <span className="image2 main"><img src={pic02} alt="" /></span>
          <p><i>Vida sentida</i> explica la experiencia personal de convivir con un trastorno obsesivo compulsivo. Es una experiencia vital que permite hacer un proceso
           de autoconocimiento inmenso, descubrir y desarrollar el propio potencial y transformar el dolor en amor. Toda persona puede reencontrar su sabiduría, 
           sean cuales sean las limitaciones a superar, y recuperar el equilibrio interior para abrir su corazón a la vida, tomar conciencia de quien es y vivir plenamente.</p>
          <p><center>Cuando amamos lo que somos nuestra esencia llena el mundo.</center></p>
          <p>
          «Una historia sobre el amor a uno mismo, a la vida. Una historia de superación personal que pasa por el miedo, la vergüenza, la rabia, la incomprensión, la alegría, la incertidumbre, la tristeza, la frustración… y aun así escrita con una coherencia y una humanidad absolutas.» Aloma Cuiné Comas
          </p>
          <p>
          «Hay historias que se escriben desde un lugar superior, que son flotadores de esperanza. Si las encuentras, te salvan la vida porque explican tu historia. Por eso es necesario tu libro, es un grandísimo flotador.» Bego García Rodríguez
          </p>
          <p>
          «Este niño, tu libro, me ha hecho sentir inocente, como un niño que va de la mano de su hermana mayor. Gemma, te agradezco que me hayas mostrado tus manos, que modelan tu vida. Gracias por poner tu bella chispa de luz a mi paso por este camino.» Chico Sandoval Osorio
          </p>
          <p>
          «Este libro es un canto a la vida y a nuestra divinidad. Leerlo ha sido un gran regalo y una nueva oportunidad para sentir la luz y la fuerza que todos llevamos dentro.» Cristina Álvarez Vila
          </p>
          <p>
          «Todo lo que escribes es agua fresca y clara que fluye sin esfuerzo de tu corazón al corazón de todos aquellos que vibran y quieren vibrar en el amor. Es una llamada directa a la esencia de la luz que somos.» Dolors Beltran Boixadera
          </p>
          <p>
          «Este libro no es sólo la historia de alguien que ha sufrido un trastorno mental. Es la historia de cada uno de nosotros, en la que se evidencia que nuestra fragilidad puede ser transformada en la fortaleza que nos hace crecer y ser mejores personas.» Dra. Emma Carné
          </p>
          <p>
          «Gracias, Gemma, este libro es la prueba…
          Cuando la dualidad de la mente se rinde a la fuerza del Cora-zón, con entrega total, ¡ocurren los pequeños milagros!
          Un regalo de libro.» Laura Mayoral Arnay
          </p>
          <p>
          «Tienes un don para escribir y, además, tienes una historia y una trayectoria vital brutal. Y en el libro se respira. Cierras un círculo, abres una nueva etapa y sigues caminando, sabiendo que has llegado a una cima, saboreando el hito y vislumbrando nuevos horizontes. Siendo consciente de que lo esencial para vivir está en tu interior y en el de todos los que compartimos este viaje apasionante.» Lurdes Castells Castany
          </p>
          <p>
          «Testimonio de superación-transformación. Escrito desde el corazón, para llegar al corazón.» Gemma León Roig
          </p>
          <p>
          «Un libro que no te deja indiferente. Y no sólo por tu experiencia con el TOC y el enfoque que ofreces de los trastornos mentales en general, sino porque éste es, por encima de todo, un libro de Vida, de Valentía, de Amor Incondicional, de Inconformismo, de Esperanza, de Aprendizaje, de Lucha. Un inmenso regalo para todos. Gracias.» Marta Paixau Puigoriol
          </p>
          <p>
          «Te felicito por este hijo que has engendrado… desde la oscuridad más profunda… y siendo su nacimiento pura luz para todo aquel que lo pueda acariciar…» Núria Trullà Roqueta
          </p>
          <p>
          «Leerte implica leerme, creo que este libro sólo se puede leer así.» Queralt Bancells Farràs
          </p>
          <p>
          «Un libro lleno de esperanza y amor. Un libro lleno de ti, lleno de mí, lleno de todos. Gracias infinitas. Te quiero.» Roser Farràs Prat
          </p>
          <p>
          «Caminar sin detenerse, hacia el interior, con la fría desnudez del cuerpo sobre las piedras, detenerse un momento, detenerme, respirarme como quien solloza en las orillas. Dejarse cautivar, dejarme acercar a los ríos por los que debo atravesar la luna blan-ca. Y sentir por un instante el ser que soy y en el que quiero seguir adentrándome. Caminar, como quien atraviesa un desierto lleno de yo, de vida, de esperanza. Sentir, sin parar, caricias blancas.» Xavier Rivero Tarruella
          </p>
          {close}
        </article>

        <article id="about" className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">El libro</h2>
          <span className="image main"><OutboundLink href="https://bit.ly/vidasentida-es" target="_blank"><img src={pic03} alt="Vida sentida" /></OutboundLink></span>
          <p>Puedes comprar el libro en Amazon en formato papel o ebook (Kindle) clicando en la imagen de arriba o en el siguiente enlace: &nbsp;&nbsp;
            <OutboundLink href="https://bit.ly/vidasentida-es" target="_blank">Vida sentida en amazon.es</OutboundLink>
          </p>
          {close}
        </article>

        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Contacto</h2>
          <form name="contact_es" method="post" action="/success-es" data-netlify="true" data-netlify-honeypot="bot-field">
            <input type="hidden" name="bot-field" />
            <div className="field half first">
              <label htmlFor="name">Nombre</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Mensaje</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li><input type="submit" value="Envia mensaje" className="special" /></li>
              <li><input type="reset" value="Limpia" /></li>
            </ul>
          </form>
          <ul className="icons">
            <li><a href="#" className="icon fa-facebook"><span className="label">Facebook</span></a></li>
           </ul>
          {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main